import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import useWindowSize from "../Hooks/useWindowSize"
import ProjectItem from "../components/ProjectItem/ProjectItem"
import Carousel from "../components/Carousel/Carousel"
import useViewport from "../Hooks/useViewport"
import useSeoQuery from "../queries/useSeoQuery"

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProyectos(sort: { fields: orden, order: ASC }) {
        edges {
          node {
            nombre
            ano
            lugar
            imgDesktopListado {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
            imgMobileListado {
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
            url
          }
        }
      }
    }
  `)

  return data.allContentfulProyectos.edges
}

const Proyectos = () => {
  const data = useProjects()
  const seo = useSeoQuery()
  const size = useWindowSize()
  useViewport()
  
  return (
    <Layout>
      <Seo
        title="Proyectos"
        lang="es"
        titleSEO={seo.proyectosTitleSeo}
        description={seo.proyectosDescriptionSeo.proyectosDescriptionSeo}
      />
      <h1 className="seo-title">Proyectos de arquitectura e interiorismo en Madrid</h1>
      
      {size > 880 // ------------------------------ PROJECT ITEM DESKTOP
        ? data.map((proyect, i) => (
            <ProjectItem
              key={i}
              name={proyect.node.nombre}
              year={proyect.node.ano}
              slug={proyect.node.url}
              imgs={proyect.node.imgDesktopListado.slice(0, 4)}
            />
          ))
        : // ------------------------------------------ CAROUSEL MOBILE
          data.map((proyect, i) => (
            <div className="container">
              <Carousel
                key={i}
                images={proyect.node.imgMobileListado.slice(0, 4)}
                linked={true}
                slug={`proyectos/${proyect.node.url}`}
              />
              <div className="justify-content-center text-center mb-5 mt-2">
                <p>
                  <b>{proyect.node.nombre}</b>
                  <br />
                  <small>
                    {proyect.node.lugar}, {proyect.node.ano}
                  </small>
                </p>
              </div>
            </div>
          ))}
    </Layout>
  )
}

export default Proyectos
